html, body, #root { /* #root is the default root element in many React apps */
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  display: flex; /* Establishes this container as a flex container */
  flex-direction: column; /* Stacks children vertically */
  justify-content: center; /* Centers content vertically in the container */
  align-items: flex-start; /* Aligns children to the start of the cross axis, which is the left side in this case */
  
  text-align: left;
  color: white;
  background-color: #121212; /* Adjust the color to match the dark mode background */
  font-family: 'Fira Code', monospace;
  padding: 20px; 
  padding-right: 50px; /* Adjust the padding as needed to create space on the right */
  height: 100vh; /* Sets the height of the container to the full viewport height */
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns items to the left */
  padding: 40px;
  max-width: 800px; /* Or as needed */
  margin: auto;
}

h1 {
  font-size: 48px; /* Adjust to match the design */
  margin-bottom: 24px;
}

p {
  font-size: 18px; /* Adjust to match the design */
  line-height: 1.6; /* Adjust to match the design */
  margin-bottom: 16px;
}

a {
  color: white; /* Adjust if there's a specific color for links */
  text-decoration: none;
  font-size: 18px;
  margin-top: 24px;
}

a:hover {
  text-decoration: underline; /* Optional: if you want to indicate hover state */
}
